import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"
import WavyLink from "../components/fragments/wavy-link"
import { getPrettyDate, getStandardDate } from "../utils/dates"

export const query = graphql`
  query NotesList($limit: Int, $skip: Int) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    craft {
      blogPosts: entries(section: "notes", limit: $limit, offset: $skip) {
        title
        slug
        postDate
        ... on Craft_notes_notes_Entry {
          introduction
          image: noteThumbnail {
            title
            url(width: 720, height: 475, quality: 100, immediately: true)
          }
          imageFile {
            childImageSharp {
              fluid(maxWidth: 720, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 600, quality: 90) {
                src
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data, pageContext }) => {
  const posts = data.craft.blogPosts
  const seoImage =
    data.site.siteMetadata.siteUrl +
    data.craft.blogPosts[0].imageFile[0].childImageSharp.fixed.src

  const NotesPageIntro = styled.section`
    padding-top: 6.5rem;
    padding-bottom: 1rem;
    margin-bottom: -4rem;
    max-width: 98rem;

    @media (min-width: 46rem) {
      padding-top: 8.5rem;
      padding-bottom: 0;
    }
  `

  const NoteListContainer = styled.section`
    padding-top: 6rem;

    @media (min-width: 46rem) {
      padding-top: 8.5rem;
    }
  `

  const Note = styled.article`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    margin-bottom: 3.5rem;

    @media (min-width: 46rem) {
      gap: 1rem;
      margin-bottom: 5rem;
      padding-top: 4rem;
      padding-bottom: 4rem;

      &:nth-of-type(even) {
        direction: rtl;

        * {
          direction: ltr;
          text-align: right;
        }
      }
    }
  `

  const ImageContainer = styled.div`
    grid-column-end: span 12;

    @media (min-width: 46rem) {
      grid-column-end: span 6;
    }
  `

  const TextContainer = styled.div`
    display: flex;
    align-items: center;
    grid-column-end: span 12;

    @media (min-width: 46rem) {
      grid-column-end: span 5;
      grid-column-start: 8;
    }

    div {
      h2 {
        @media (min-width: 46rem) {
          font-size: 2.8rem;
          margin-bottom: 1.5rem;
        }

        span,
        a {
          margin: 0;
          padding: 0;
          line-height: inherit;
        }
      }

      p {
        margin: 0;
      }
    }
  `

  const PostDate = styled.p`
    font-size: 0.7rem;
    color: ${props => props.theme.grays.mid};
    margin: 0 0 0.75rem 0.25rem;
  `

  const NotePagination = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;

    div {
      grid-column-end: span 4;
      text-align: center;

      &:first-of-type {
        text-align: left;
      }

      &:last-of-type {
        text-align: right;
      }
    }
  `

  return (
    <Layout>
      <SEO
        title="Notes"
        description="Thoughts, notes, daily life and everything else along the way"
        image={seoImage}
        pathname={`notes`}
        blog
      />

      {pageContext.currentPage === 1 && (
        <NotesPageIntro>
          <h1 className="pageTitle">Thoughts on things...</h1>
        </NotesPageIntro>
      )}

      <NoteListContainer>
        {posts.map((blogEntry, i) => (
          <Note
            key={i}
            data-sal="fade-in"
            data-sal-delay="100"
            data-sal-duration="450"
            data-sal-easing="ease"
          >
            <ImageContainer>
              <Link to={`/notes/${blogEntry.slug}`} title={blogEntry.title}>
                {!!blogEntry.imageFile[0].childImageSharp.fluid ? (
                  <Img
                    fluid={blogEntry.imageFile[0].childImageSharp.fluid}
                    alt={blogEntry.image.title}
                    title={blogEntry.image.title}
                  />
                ) : (
                  <img
                    src={blogEntry.image.url}
                    alt={blogEntry.image.title}
                    title={blogEntry.image.title}
                  />
                )}
              </Link>
            </ImageContainer>
            <TextContainer>
              <div>
                <h2>
                  <WavyLink
                    href={`/notes/${blogEntry.slug}`}
                    title={blogEntry.title}
                    text={blogEntry.title}
                    textColor="blackText"
                    lineColor="yellowLine blueLineHover"
                  />
                </h2>
                <PostDate>
                  <time dateTime={getStandardDate(blogEntry.postDate)}>
                    {getPrettyDate(blogEntry.postDate)}
                  </time>
                </PostDate>

                <p
                  dangerouslySetInnerHTML={{ __html: blogEntry.introduction }}
                />
              </div>
            </TextContainer>
          </Note>
        ))}
      </NoteListContainer>

      <NotePagination>
        <div>
          {pageContext.prevUrl ? (
            <Link to={pageContext.prevUrl} title="Newer Notes">
              &larr; Newer
            </Link>
          ) : (
            <p>&larr; Newer</p>
          )}
        </div>

        <div>
          {pageContext.currentPage} of {pageContext.totalPages}
        </div>

        <div>
          {pageContext.nextUrl ? (
            <Link to={pageContext.nextUrl} title="Older Notes">
              Older &rarr;
            </Link>
          ) : (
            <p>Older &rarr; </p>
          )}
        </div>
      </NotePagination>
    </Layout>
  )
}

export default IndexPage
